import React from "react";

import { graphql } from "gatsby";

import Elements from "../../components/shared/Elements";
import Page from "../../components/shared/Page";

const StellenangeotePage = ({
    data: {
        page: { elements },
    },
}) => {
    return (
        <Page>
            <Elements {...{ elements }} />
        </Page>
    );
};

export default StellenangeotePage;

export const query = graphql`
    query {
        page: pagesYaml(templateKey: { eq: "pages/stellenangebote" }) {
            ...Page
            elements {
                ...EntireElement
            }
        }
    }
`;
